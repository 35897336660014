<template>
    <span>
        <span v-if="['SUCCESS', 'COMPLETED', 'ACTIVE', 'CONFIRMED', 'SOLDOUT'].indexOf(status.toUpperCase())!==-1" class="text-success" style="font-size: 1em;"><b>{{status}}</b></span>
        <span v-else-if="['UNDER_VERIFICATION', 'PENDING', 'NOTSTARTED', 'OPEN', 'REQUESTED', 'UNLOCKED', 'INACTIVE'].indexOf(status.toUpperCase())!==-1" class="text-primary" style="font-size: 1em;"><b>{{status}}</b></span>
        <span v-else-if="['FAILED', 'CANCEL', 'ARCHIVED', 'TERMINATED', 'REJECTED', 'EXPIRED', 'BLOCKED'].indexOf(status.toUpperCase())!==-1" class="text-danger" style="font-size: 1em;"><b>{{status}}</b></span>
        <span v-else class="text-warning">{{status}}</span>
    </span>
</template>
<script>
export default {
    props: ['status']
}
</script>
<style scoped>
span {
    text-transform: uppercase;
}
</style>