import { createRouter, createWebHistory } from 'vue-router'
import Services from '@/services/services';
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Skelton',
    component: () => import('../views/Skelton.vue'),
    beforeEnter: Services.auth.isAuthenticated,
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue'),
      },
      {
        path: '/financials/',
        name: 'Financials',
        component: () => import('../views/Financials.vue'),
      },
      {
        path: '/user/:id/',
        name: 'UserDetails',
        component: () => import('../views/UserDetails.vue'),
        props: true
      },
      {
        path: '/users/',
        name: 'Users',
        component: () => import('../views/Users.vue')
      },
      {
        path: '/subscriptions/',
        name: 'Subscriptions',
        component: () => import('../views/Subscriptions.vue')
      },
      {
        path: '/enrollments/',
        name: 'Enrollments',
        component: () => import('../views/Enrollments.vue')
      },
      {
        path: '/communication/',
        name: 'Communication',
        component: () => import('../views/Communication.vue')
      },
      {
        path: '/plans/',
        name: 'Plans',
        component: () => import('../views/Plans.vue')
      },
      {
        path: '/tasks/',
        name: 'Tasks',
        component: () => import('../views/Tasks.vue')
      },
      {
        path: '/transactions/',
        name: 'Transactions',
        component: () => import('../views/Transactions.vue')
      },
      {
        path: '/currencies/',
        name: 'Currencies',
        component: () => import('../views/Currencies.vue')
      },
      {
        path: '/courses/',
        name: 'Courses',
        component: () => import('../views/Courses.vue')
      },
      {
        path: '/payments/',
        name: 'Payments',
        component: () => import('../views/Payments.vue')
      },
      {
        path: '/settings/',
        name: 'Settings',
        component: () => import('../views/Settings.vue')
      },
      {
        path: '/requests-actions/',
        name: 'Actions',
        component: () => import('../views/Actions.vue')
      },
      {
        path: '/tax/',
        name: 'Tax',
        component: () => import('../views/Tax.vue')
      },
      {
        path: '/discounts/',
        name: 'Discounts',
        component: () => import('../views/Discounts.vue')
      },
      {
        path: '/lookups/',
        name: 'Lookups',
        component: () => import('../views/Lookups.vue')
      },
      {
        path: '/banners/',
        name: 'Banners',
        component: () => import('../views/Banners.vue')
      },
      {
        path: '/profiles/',
        name: 'Profiles',
        component: () => import('../views/Profiles.vue')
      },
      {
        path: '/questionaries/',
        name: 'Questionaries',
        component: () => import('../views/Questionaries.vue')
      },
      {
        path: '/products/',
        name: 'Products',
        component: () => import('../views/Products.vue')
      },
      {
        path: '/notifications/',
        name: 'Notifications',
        component: () => import('../views/Notifications.vue')
      },
      {
        path: '/coupons/',
        name: 'Coupons',
        component: () => import('../views/Coupons.vue')
      },
      {
        path: '/scheduler/',
        name: 'Scheduler',
        component: () => import('../views/Scheduler.vue')
      },
      {
        path: '/reports/new/',
        name: 'ReportsNew',
        component: () => import('../views/ReportsNew.vue')
      },
      {
        path: '/reports/',
        name: 'Reports',
        component: () => import('../views/Reports.vue')
      },
    ]
  },
  {
    path: '/login/',
    name: 'Login',
    component: () => import('../views/auth.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
