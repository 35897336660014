import { mapState } from "vuex";
export default {
  data() {
    return {
      dataList: {
        results: []
      },
      regionsList: [],
      serviceModule: null,
      selectedProduct: null,
      loading_list: false,
      pageSize: 20,
      sort_by: '-created_on',
      search_term: '',
      tab: 'Courses',
      domainsList: null,
      topicsList: null,
      categoriesList: null,
      user_type: 'all',
      defaultPayload: {
        title: null,
        description: null,
        price: null,
        credits: null,
        order: null,
        validity: null,
        rules: null,
        status: null,
      },
      payload: null,
      visible_form: false,
      error: {},
      uploadingStatus: null,
      lookups: null
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
      cats: (state) => state.cats,
      subCats: (state) => state.subCats,
      catMappings: (state) => state.catMappings,
      lookupsSelector: (state) => state.lookups
    })
  },
  watch: {
    lookupsSelector: function(newVal) {
        if(newVal) {
          this.lookups = newVal;
        }
    }
  },
  created() {
    this.payload = this.defaultPayload;
    if(this.profile && this.profile.email !== undefined && this.serviceModule) {
      this.getdataList();
    }
  },
  methods: {
    getRegionsList() {
      const that = this;
      that.$cs.region.list({
        pageSize: 100
      }).then(
        (data) => {
          that.regionsList = data.data.results;
        },
        () => {
          console.log("error");
        }
      );
    },
    handleTableAction(value) {
      switch (value.type) {
        case "pageSize":
          this.pageSize = value.value;
          this.getdataList();
          break;
        case "next":
        case "prev":
          this.getdataList(value.value);
          break;
      }
    },
    extraParams() {
      return {};
    },
    getdataList(page = null) {
      const that = this;
      let params = {
        sort: that.sort_by,
        search: that.search_term,
        ...this.extraParams()
      }
      that.user_type != 'all' ? params.user_type = this.user_type : null
      that.$cs[this.serviceModule]
        .list({
          resource: page,
          pageSize: that.pageSize,
          page,
          params
        })
        .then(
          data => {
            that.dataList = Object.assign(data.data);
          },
          () => {
            console.log("error");
          }
        );
    },
    sortBy(field) {
      if (
        field === this.sort_by ||
        this.sort_by === `-${field}`
      ) {
        if (!this.sort_by.startsWith("-")) {
          this.sort_by = `-${field}`;
        } else {
          this.sort_by = field;
        }
      } else {
        this.sort_by = field;
      }
      this.getdataList();
    },
    clearFilter() {
      this.user_type = 'all';
      this.search_term = '';
      this.getdataList();
    },
    validateData() {
      const that = this;
      that.error = {};
      var proceed = true;
      if (that.payload.category === null || that.payload.category === "") {
        this.error.category = "Category is required";
        proceed = false;
      }
      if (that.payload.sub_category === null || that.payload.sub_category === "") {
        this.error.sub_category = "Sub Category is required";
        proceed = false;
      }
      if (that.payload.title === null || that.payload.title === "") {
        this.error.title = "Title is required";
        proceed = false;
      }
      return proceed;
    },
    saveData(mode = "create") {
      const that = this;
      if (this.validateData()) {
        that.processing = true;
        let resource = null;
        if (mode === 'update') {
          resource = `${that.$cs[this.serviceModule].api}${that.payload.id}/`;
        }
        that.$cs[this.serviceModule][mode]({ data: that.payload, resource })
          .then(res => {
            that.getdataList();
            that.error = {};
            that.visible_form = false;
          },
            err => {
              if (err.response.status === 400) {
                that.error = err.response.data;
              }
            })
      }
    },
    openFile() {
      document.getElementById('imgfile').click();
      // this.$ref.imgFile.click();
    },
    uploadFile() {
      const File = document.getElementById('imgfile').files[0];
      if (File !== undefined) {
        this.uploadingStatus = 'Uploading....';
        this.$cs.img.upload(File, 'thumbnail').then(res => {
          this.payload.thumbnail = res;
          this.uploadingStatus = "Uploaded Successfully";
        }, err => {
          this.uploadingStatus = err;
        });
      }
    },
    newData() {
      const that = this;
      if (this.validateData()) {
        that.processing = true
        that.$cs[this.serviceModule].create({ data: that.payload })
          .then(res => {
            that.getdataList();
            that.error = {};
            that.visible_form = false;
          },
            err => {
              if (err.response.status === 400) {
                that.error = err.response.data;
              }
            })
      }
    },
    updateData() {
      const that = this;
      if (this.validateData()) {
        that.$cs[this.serviceModule].update({
          data: that.payload,
          resource: `${that.$cs[this.serviceModule].api}${that.payload.id}/`,
        })
          .then(res => {
            that.getdataList();
            that.error = {};
            that.visible_form = false;
          },
            err => {
              if (err.response.status === 400) {
                that.error = err.response.data;
              }
            })
      }
    },
    resetPayload() {
      const defaultPayload = this.defaultPayload;
      this.payload = Object.assign({}, defaultPayload);
      this.error = {};
    },
    dataEdit(item) {
      const dt = Object.assign({}, item);
      this.payload = dt;
      this.visible_form = true;
    },
    getDomainsList(extraParams = {}) {
      const that = this;
      that.$cs.course.list({
        pageSize: 1000,
        params: {
          course_type: 'DOMAIN',
          ...extraParams
        }
      }).then(
        (data) => {
          that.domainsList = Object.assign(data.data);
        },
        () => {
          console.log("error");
        }
      );
    },
    getTopicsList(extraParams = {}) {
      const that = this;
      that.$cs.course.list({
        pageSize: 1000,
        params: {
          course_type: 'TOPIC',
          ...extraParams
        }
      }).then(
        (data) => {
          that.topicsList = Object.assign(data.data);
        },
        () => {
          console.log("error");
        }
      );
    },
    getCategoryList() {
      const that = this;
      that.$cs.course.list({
        pageSize: 1000,
        params: {
          course_type: 'CATEGORY'
        }
      }).then(
        (data) => {
          that.categoriesList = Object.assign(data.data);
        },
        () => {
          console.log("error");
        }
      );
    },
  }
};