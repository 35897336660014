<script>
import axios from "axios";

/* eslint class-methods-use-this: "error" */
class REST {
	pageSize = 20;

	// eslint-disable-next-line
	get token() {
		return localStorage.getItem("Token");
	}

	// eslint-disable-next-line
	get token_title() {
		return process.env.TOKEN_TITLE;
	}

	list({ resource = null, params = {}, pageSize = null }) {
		const parameters = params;
		let resourceUrl = resource;
		if (resource === null) {
			resourceUrl = this.api;
		}
		parameters.limit = pageSize === null ? this.pageSize : pageSize;
		return axios.get(resourceUrl, {
			params: parameters,
		});
	}

	retrive({ resource = null, params = {}, page = 1, pageSize = null }) {
		const parameters = params;
		let resourceUrl = resource;
		if (resourceUrl === null) {
			resourceUrl = this.api;
		}
		parameters.page = page;
		parameters.pageSize = pageSize === null ? this.pageSize : pageSize;
		return axios.get(resourceUrl, {
			params: parameters,
		});
	}

	create({ resource = null, params = {}, data = {} }) {
		const parameters = params;
		let resourceUrl = resource;
		if (resourceUrl === null) {
			resourceUrl = this.api;
		}
		return axios({
			method: "post",
			url: resourceUrl,
			data,
			params: parameters,
		});
	}

	update({ resource = null, params = {}, data = {} }) {
		const parameters = params;
		let resourceUrl = resource;
		if (resourceUrl === null) {
			resourceUrl = `${this.api}${data.id}/`;
		}
		return axios({
			method: "put",
			url: resourceUrl,
			data,
			params: parameters,
		});
	}

	delete({ resource = null, params = {} }) {
		const parameters = params;
		let resourceUrl = resource;
		if (resourceUrl === null) {
			resourceUrl = this.api;
		}
		return axios({
			method: "delete",
			url: resourceUrl,
			params: parameters,
		});
	}

	// To generate the signed link for upload to S3
	getSignedLinkForUpload({
		fileName,
		fileType,
		access = true,
		params = {},
		resource = null,
		type = "assets",
	}) {
		const parameters = params;
		let resourceUrl = resource;
		if (resourceUrl === null) {
			resourceUrl = `${process.env.VUE_APP_API_URL}/api/get-signed-url/`;
		}
		return axios({
			method: "post",
			url: resourceUrl,
			data: {
				file_name: fileName,
				file_type: fileType,
				public: access,
				type,
			},
			params: parameters,
		});
	}

	getDownloadLink(fileName, filePath) {
		const parameters = {};
		return axios({
			method: "post",
			url: `${process.env.VUE_APP_API_URL}/api/get-download-link/`,
			data: {
				file_name: fileName,
				file_path: filePath,
			},
			params: parameters,
		});
	}

	getFileAccess(filePath) {
		return axios({
			method: "post",
			url: `${process.env.VUE_APP_API_URL}/api/get-download-link/`,
			data: {
				file_path: filePath,
			},
		});
	}
}

// Authentication
class Authentication {
	// To set the token in localstorage
	setToken = (token) => {
		localStorage.setItem("Token", token);
	};
	setProfile = (profile) => {
		localStorage.setItem("Profile", profile);
	};

	getToken = () => localStorage.getItem("Token") || "";

	getCurrentUser = () => localStorage.getItem("profile") || {};

	// To remove the token from localstorage
	removeLocalStorage = () => {
		localStorage.clear();
	};

	// To check user is authenticated
	// To validate the token
	isAuthenticated = (to, from, next) => {
		if (localStorage.getItem("Token")) {
			next();
		} else {
			window.location.href = "/login/";
		}
	};

	isnotAuthenticated = (to, from, next) => {
		if (localStorage.getItem("Token")) {
			window.location.href = "/dashboard/";
		} else {
			next();
		}
	};

	// To login using username(email) & password
	login = (username, password) => {
		localStorage.setItem("API_DOMAIN", process.env.VUE_APP_API_URL);
		// Validate creds
		const creds = {
			mobile: username,
			password,
		};
		return axios.post(`${process.env.VUE_APP_API_URL}/login/`, creds);
	};

	verify = (data={}) => {
		return axios.post(`${process.env.VUE_APP_API_URL}/login/verify/`, data);
	};

	// To signup using payload
	signup = (payload) => {
		localStorage.setItem("API_DOMAIN", process.env.VUE_APP_API_URL);
		return axios.post(`${process.env.VUE_APP_API_URL}/signup/`, payload);
	};

	// To remove the token from server & localstorage
	logout() {
		this.removeLocalStorage();
		window.location.href = "/login/";
	}

	// send reset password link
	forgotPassword(email) {
		return axios({
			method: "post",
			url: `${process.env.VUE_APP_API_URL}/forgot-password/`,
			data: {
				email,
			},
		});
	}

	// Check reset password link
	checkResetPasswordLink(id) {
		return axios({
			method: "post",
			url: `${process.env.VUE_APP_API_URL}/check-password/${id}/`,
		});
	}

	// Check & Reset Password
	checkAndResetPassword(id, password) {
		return axios({
			method: "post",
			url: `${process.env.VUE_APP_API_URL}/reset-password/${id}/`,
			data: {
				password,
			},
		});
	}
}

// Profile
class Profile extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/profile/`;

	changePassword(data) {
		return axios({
			method: "put",
			url: `${process.env.VUE_APP_API_URL}/change-password/`,
			data,
		});
	}

	verifyOTP(data) {
		return axios({
			method: "post",
			url: `${process.env.VUE_APP_API_URL}/verify-otp/`,
			data,
		});
	}

	resendOTP() {
		return axios.get(`${process.env.VUE_APP_API_URL}/verify-otp/`);
	}

	list() {
		return axios.get(this.api, {});
	}

	update({ params = {}, data = {} }) {
		const parameters = params;
		return axios({
			method: "put",
			url: this.api,
			data,
			params: parameters,
		});
	}
}

class User extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/user/`;
}

class Category extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/category/`;
}

class SubCategory extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/sub-category/`;
}

class Course extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/course/`;
}

class Plan extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/plan/`;
}

class Subscription extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/subscription/`;
}

class Settings extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/setting/`;
}

class Tax extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/tax/`;
}

class Payment extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/payment/`;
}

class Location extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/location/`;
}

class Enrollment extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/enrollment/`;
}

class Notification extends REST {
	api = `${process.env.VUE_APP_API_URL}/notification/`;
}

class Report extends REST {
	api = `${process.env.VUE_APP_API_URL}/admin/report/`;
}

class ReportGenerator extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/report-generator/`;
}

class Coupon extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/coupon/`;
}

class Slots extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/slots/`;
}

class Admin extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/admin/dashboard/`
}

class AdminFinancials extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/admin/financials/`
}

class OldOrder extends REST {
	api = `${process.env.VUE_APP_SOCKET_URL}/orders/`;
}

class Profiles extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/profiles/`;
}

class Enquires extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/product/`;
}

class Transaction extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/featured/`;
}

class Questionnaire extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/questionnaire/`;
}

class Lookup extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/lookup/`;
}

class Region extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/admin/region/`;
}

class LookupCode extends REST {
	api = `${process.env.VUE_APP_API_URL}/api/lookup-code/`;
}

class Lookups extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/lookup/`;
}

class Action extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/admin/action/`;
}

class Task extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/admin/task/`;
}

class Scheduler extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/admin/scheduler/`;
}

class Banner extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/admin/banner/`;
}

class Discount extends REST {
  api = `${process.env.VUE_APP_API_URL}/api/admin/discount/`;
}

class Utils extends REST {
	sendSMS(data) {
		return axios({
			method: "post",
			url: `${process.env.VUE_APP_API_URL}/api/send-sms/`,
			data,
		});
	}

	sendEmail(data) {
		return axios({
			method: "post",
			url: `${process.env.VUE_APP_API_URL}/api/send-email/`,
			data,
		});
	}
}

class ImageUtil extends REST {
	convert(file1) {
		return new Promise(function(resolve, reject) {
			var rawImage = new Image();
			rawImage.addEventListener("load", function() {
				resolve(rawImage);
			});
			rawImage.src = URL.createObjectURL(file1);
		}).then(function(rawImage) {
			// Convert image to webp ObjectURL via a canvas blob
			return new Promise(function(resolve, reject) {
				let canvas = document.createElement("canvas");
				let ctx = canvas.getContext("2d");
				canvas.width = rawImage.width;
				canvas.height = rawImage.height;
				canvas.toBlob(function(blob) {
					var file245 = new File(
						[blob],
						Math.random()
							.toString(36)
							.substr(2, 9) + ".webp",
						{ type: "image/webp" }
					);
					console.log(file245);
					// resolve(URL.createObjectURL(blob));
					resolve(file245);
				}, "image/webp");
			});
		});
	}

	uploadWithConvert(file1, path = "public/", access = true) {
		const that = this;
		return new Promise((resolve, reject) => {
			that.convert(file1).then(
				function(file2) {
					that
						.getSignedLinkForUpload({
							fileName: file2.name,
							fileType: file2.type,
							access: access,
							type: path,
						})
						.then(
							(response) => {
								const signedData = response.data;
								const options = {
									headers: {
										"Content-Type": file2.type,
									},
									onUploadProgress(progressEvent) {},
								};
								axios
									.put(signedData.signed_url, file2, options)
									.then(() => {
										resolve(signedData.url);
									})
									.catch(() => {
										reject("Unable to Upload file");
									});
							},
							(err) => {
								reject("Unable to Get Signed URL");
							}
						);
				},
				(err) => {
					reject("Unable to Process file");
				}
			);
		});
	}

	upload(File, path = "public", access = true) {
    const that = this;
		return new Promise((resolve, reject) => {
			that
				.getSignedLinkForUpload({
					fileName: Math.random().toString(36).substr(2, 9)+File.name,
					fileType: File.type,
					access: access,
					type: path,
				})
				.then(
					(response) => {
						const signedData = response.data;
						const options = {
							headers: {
								"Content-Type": File.type,
							},
							onUploadProgress(progressEvent) {},
						};
						axios
							.put(signedData.signed_url, File, options)
							.then(() => {
								resolve(signedData.url);
							})
							.catch(() => {
								reject("Unable to Upload file");
							});
					},
					(err) => {
						reject("Unable to Get Signed URL");
					}
				);
		});
	}
}

export default {
	REST,
	auth: new Authentication(),
	profile: new Profile(),
	enrollment: new Enrollment(),
	user: new User(),
	category: new Category(),
	subcategory: new SubCategory(),
	course: new Course(),
	plan: new Plan(),
	subscription: new Subscription(),
	banner: new Banner(),
	coupon: new Coupon(),
	payment: new Payment(),
	setting: new Settings(),
	tax: new Tax(),
	notification: new Notification(),
	util: new Utils(),
	location: new Location(),
	slot: new Slots(),
	report: new Report(),
	reportGenerator: new ReportGenerator(),
	img: new ImageUtil(),
	oldorder: new OldOrder(),
	profiles: new Profiles(),
	enquires: new Enquires(),
	transaction: new Transaction(),
	lookupCode: new LookupCode(),
	lookup: new Lookup(),
	lookups: new Lookups(),
	action: new Action(),
	region: new Region(),
	task: new Task(),
	admin: new Admin(),
	financials: new AdminFinancials(),
	discount: new Discount(),
	scheduler: new Scheduler(),
	questionnaire: new Questionnaire(),
};
</script>
