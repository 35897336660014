import { createStore } from 'vuex'
import Services from '../services/services.vue'
var $cs = Services;
export default createStore({
  state: {
    profile: JSON.parse(localStorage.getItem("user_details")) || {},
    cats: null,
    subCats: null,
    catMappings: null,
    category: null,
    subCategory: null,
    deliveryBoys: null,
    hub: null,
    hubMapping: {},
    categoryMapping: {},
    subCategoryMapping: {},
    notifications: null,
    orders: null,
    lookups: null
  },
  mutations: {
    addNotifications(state, data) {
      state.notification = data;
      state.notifications = [...state.notifications, ...data.results];
      state.unreadCount = 0;
      state.notifications.forEach(item => {
        if(item.status && item.status==="unread") state.unreadCount++;
      });
      console.log("added Notifications...");
    },
    setLookups(state, data) {
      let lookupObj = {};
      data.results.forEach(item => {
        lookupObj[item.id] = item;
      });
      state.lookups = lookupObj;
      console.log("added Lookups...", lookupObj);
    },
    setCats(state, data) {
      state.cats = data;
      let lookupObj = {};
      let lookupMapping = {};
      data.forEach((item) => {
        lookupMapping[item.id] = item.title;
        lookupObj[item.id] = item.cat_list;
      });
      state.subCats = lookupObj;
      state.catMappings = lookupMapping;
    },
  },
  actions: {
    resetData(context) {
      context.dispatch("getLookups");
      context.dispatch("getCats");
    },
    getLookups(context) {
      $cs.lookups.list({
        pageSize: 1000
      }).then(
        (res) => {
          context.commit("setLookups", res.data);
        },
        (err) => {
          console.log(err.response);
        }
      );
    },
    getCats(context) {
      $cs.lookups.list({
        resource: `${$cs.lookups.api}cats/`,
        pageSize: 1000
      }).then(
        (res) => {
          context.commit("setCats", res.data);
        },
        (err) => {
          console.log(err.response);
        }
      );
    },
  },
  modules: {
  }
})
